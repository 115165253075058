<template>
    <div class="main-container"  dir="ltr">
        <div class="row" dir="rtl">
            <div class="col-xl-6 col-md-6">
                <div class="my-account-content2" dir="rtl">
                    <div class="header-profile-login">
                        <div class="row justify-content-between">
                            <div class="col-10 col-sm-10 col-lg-10 col-xl-10">
                                <h6 class="text-center">اشتراك في المنصة</h6>
                            </div>
                            <div class="col-2 col-sm-2 col-lg-2 col-xl-2" style="padding-right: 0;">
                                <img style="width: 45px;float: left;margin-top: -18px;margin-left: -15px;" class="logo" src="../../assets/images/mtn.png" />
                            </div>
                        </div>
                        <form id="loginform"  class="row" > 
                          <div class="col-12">
                            <p>jhgjhsghjdf jhghjgsfjgjhagsdf hjgjhsgfhgdsjf jhgjhgsf</p>
                          </div>
                          <div class="col-6">
                            <button @click="GotoHome()" class="button button-primary" style="width: 100%; background: #fc0;color: #000;">
                                    <span v-if="loading" class="px-1 arabickufi">سيتم تحويلك للرئيسية</span> &nbsp;
                                    <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                                    <span v-else class="arabickufi">رجوع</span>
                            </button>
                          </div>
                          <div class="col-6">
                            <p class="login-submit">
                                <button class="button button-primary" @click="onSubscribe()" style="width: 100%; background: #fc0;color: #000;">
                                    <span v-if="loading" class="px-1 arabickufi">جاري تسجيل الدخول</span> &nbsp;
                                    <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                                    <span v-else class="arabickufi">إشتـــراك</span>
                                </button>
                                <!-- <button type="submit" style="width: 100%;background: #078CC5;" name="wp-submit" id="wp-submit" class="button button-primary" value="إشتـــراك"></button> -->
                            </p>
                          </div>
                           
                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { HTTPSPA } from '@/Api/http-Spay';
import { useToast } from "vue-toastification";
export default {
name:'AppSubscribe',
data() {
    return {
        msisdn: "",
        loading: false,
        phone:""
    };
  },
  mounted() {
    if (this.$cookie.isCookieAvailable("msisdn") && this.$cookie.isCookieAvailable("status")) {
        // this.$router.push({ path: "/" })
    }
  },
methods: {
    async onSubscribe() {
      window.open("http://courseatk.net/MTN_LandingPage_APIs/subscribe.php?portalId=1");
            
    },
    async GotoHome(){
       this.$router.push({ path: "/" })
    },
  },
}
</script>

<style>

</style>